import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Button from "../ui-components/Button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import NotFoundCurves from "../icons/NotFoundCurves";
import { DeepNonNullable } from "ts-essentials";
import { Error404DataQuery } from "../../graphqlTypes";

const NotFoundPage = () => {
  const data = useStaticQuery<DeepNonNullable<Error404DataQuery>>(graphql`
    query Error404Data {
      strapiError404 {
        pageTitle
        title
        subTitle
        linkToFrontpageText
      }
    }
  `);

  const { pageTitle, title, subTitle, linkToFrontpageText } =
    data.strapiError404;

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block mt-44 relative" style={{ height: "750px" }}>
        <NotFoundCurves
          style={{ zIndex: -1, top: "-350px" }}
          className="absolute left-0 bottom-0 right-0 m-auto"
        />
        <div className="mx-auto max-w-min text-center flex flex-col items-center gap-4">
          <h1 className="text-9xl text-white mb-0 mt-11">{title}</h1>
          <span className="text-2xl sm:text-5xl text-white whitespace-nowrap">
            {subTitle}
          </span>
          <Button color="orange" className="max-w-min whitespace-nowrap my-6">
            <Link to="/">{linkToFrontpageText}</Link>
          </Button>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default NotFoundPage;
