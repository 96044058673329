import * as React from "react";

function NotFoundCurves(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={156} height={690} fill="none" {...props}>
      <path fill="#38B9E2" d="M61 592.221h4v34.826h-4z" />
      <path fill="#38E2A5" d="M75 592.221h4v34.826h-4z" />
      <path fill="#82CA69" d="M89 592.221h4v34.826h-4z" />
      <path fill="#C7DB4E" d="M103 592.221h4v34.826h-4z" />
      <path
        d="M79 579.615v12.605h-4v-11.735c0-1.474.217-2.94.645-4.351l3.71-12.241c.428-1.411.645-2.877.645-4.351V544h2v16.412c0 .901-.081 1.799-.242 2.684l-2.516 13.835a14.984 14.984 0 00-.242 2.684z"
        fill="#38E2A5"
      />
      <path
        d="M89 579.615v12.605h4v-11.735c0-1.474-.217-2.94-.645-4.351l-3.71-12.241a15.002 15.002 0 01-.645-4.351V544h-2v16.412c0 .901.081 1.799.242 2.684l2.516 13.835c.161.885.242 1.783.242 2.684z"
        fill="#82CA69"
      />
      <path
        d="M103 582.804v9.416h4v-8.758c0-3.369-1.134-6.639-3.219-9.285l-6.562-8.327A15.001 15.001 0 0194 556.565V544h-2v13.223c0 2.962.877 5.857 2.52 8.322l5.96 8.937a15.004 15.004 0 012.52 8.322z"
        fill="#C7DB4E"
      />
      <path
        d="M65 582.804v9.416h-4v-8.758c0-3.369 1.134-6.639 3.219-9.285l6.562-8.327A15.001 15.001 0 0074 556.565V544h2v13.223c0 2.962-.877 5.857-2.52 8.322l-5.96 8.937a15.002 15.002 0 00-2.52 8.322z"
        fill="#38B9E2"
      />
      <path
        transform="matrix(0 1 1 0 61 544)"
        fill="url(#prefix__paint0_linear_0:1)"
        d="M0 0h34.826v46H0z"
        opacity={0.8}
      />
      <path fill="#38B9E2" d="M61 627.045h4v62.954h-4z" />
      <path fill="#38E2A5" d="M75 627.045h4v62.954h-4z" />
      <path fill="#82CA69" d="M89 627.045h4v62.954h-4z" />
      <path fill="#C7DB4E" d="M103 627.045h4v62.954h-4z" />
      <path
        transform="rotate(-90 61 690)"
        fill="url(#prefix__paint1_linear_0:1)"
        d="M61 690h62.954v46H61z"
      />
      <path
        d="M77 118V56c0-16.569-13.431-30-30-30H24"
        stroke="#38E2A5"
        strokeWidth={4}
      />
      <path
        d="M133 2h-12c-16.569 0-30 13.431-30 30v86"
        stroke="#82CA69"
        strokeWidth={4}
      />
      <path
        d="M156 38h-31c-11.046 0-20 8.954-20 20v60"
        stroke="#C7DB4E"
        strokeWidth={4}
      />
      <path
        d="M63 118V70c0-11.046-8.954-20-20-20H0"
        stroke="#38B9E2"
        strokeWidth={4}
      />
      <path
        d="M79 124.292V115h-4v8.215c0 1.841.339 3.666 1 5.385l3 7.8c.661 1.719 1 3.544 1 5.385V153h2v-12.292c0-1.135-.129-2.266-.384-3.373l-2.232-9.67a15.012 15.012 0 01-.384-3.373z"
        fill="#38E2A5"
      />
      <path
        d="M89 124.292V115h4v8.215c0 1.841-.339 3.666-1 5.385l-3 7.8c-.661 1.719-1 3.544-1 5.385V153h-2v-12.292c0-1.135.129-2.266.384-3.373l2.232-9.67c.255-1.107.384-2.238.384-3.373z"
        fill="#82CA69"
      />
      <path
        d="M103 120.505V115h4v4.787c0 3.978-1.58 7.793-4.393 10.606l-4.214 4.214A15 15 0 0094 145.213V153h-2v-8.505c0-3.548 1.257-6.981 3.55-9.69l3.9-4.61a15.006 15.006 0 003.55-9.69z"
        fill="#C7DB4E"
      />
      <path
        d="M65 120.505V115h-4v4.787a15 15 0 004.393 10.606l4.214 4.214A15 15 0 0174 145.213V153h2v-8.505c0-3.548-1.257-6.981-3.55-9.69l-3.9-4.61a15.003 15.003 0 01-3.55-9.69z"
        fill="#38B9E2"
      />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={156}
        height={153}
      >
        <path
          d="M77 118V56c0-16.569-13.431-30-30-30H24"
          stroke="#38E2A5"
          strokeWidth={4}
        />
        <path
          d="M133 2h-12c-16.569 0-30 13.431-30 30v86"
          stroke="#82CA69"
          strokeWidth={4}
        />
        <path
          d="M156 38h-31c-11.046 0-20 8.954-20 20v60"
          stroke="#C7DB4E"
          strokeWidth={4}
        />
        <path
          d="M63 118V70c0-11.046-8.954-20-20-20H0"
          stroke="#38B9E2"
          strokeWidth={4}
        />
        <path
          d="M79 124.292V115h-4v8.215c0 1.841.339 3.666 1 5.385l3 7.8c.661 1.719 1 3.544 1 5.385V153h2v-12.292c0-1.135-.129-2.266-.384-3.373l-2.232-9.67a15.012 15.012 0 01-.384-3.373z"
          fill="#38E2A5"
        />
        <path
          d="M89 124.292V115h4v8.215c0 1.841-.339 3.666-1 5.385l-3 7.8c-.661 1.719-1 3.544-1 5.385V153h-2v-12.292c0-1.135.129-2.266.384-3.373l2.232-9.67c.255-1.107.384-2.238.384-3.373z"
          fill="#82CA69"
        />
        <path
          d="M103 120.505V115h4v4.787c0 3.978-1.58 7.793-4.393 10.606l-4.214 4.214A15 15 0 0094 145.213V153h-2v-8.505c0-3.548 1.257-6.981 3.55-9.69l3.9-4.61a15.006 15.006 0 003.55-9.69z"
          fill="#C7DB4E"
        />
        <path
          d="M65 120.505V115h-4v4.787a15 15 0 004.393 10.606l4.214 4.214A15 15 0 0174 145.213V153h2v-8.505c0-3.548-1.257-6.981-3.55-9.69l-3.9-4.61a15.003 15.003 0 01-3.55-9.69z"
          fill="#38B9E2"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          transform="rotate(-90 61 153)"
          fill="url(#prefix__paint2_linear_0:1)"
          d="M61 153h83v46H61z"
          opacity={0.8}
        />
      </g>
      <path d="M76 153h-2v391h2V153z" fill="#0F2D4C" />
      <path d="M82 153h-2v391h2V153z" fill="#0F3540" />
      <path d="M88 153h-2v391h2V153z" fill="#1E3034" />
      <path d="M94 153h-2v391h2V153z" fill="#2C342F" />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_0:1"
          x1={0}
          y1={26.661}
          x2={26.546}
          y2={26.661}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#050A27" />
          <stop offset={1} stopColor="#050A27" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_0:1"
          x1={61}
          y1={716.661}
          x2={108.988}
          y2={716.661}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#050A27" />
          <stop offset={1} stopColor="#050A27" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear_0:1"
          x1={61}
          y1={179.661}
          x2={124.268}
          y2={179.661}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#050A27" />
          <stop offset={1} stopColor="#050A27" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default NotFoundCurves;
